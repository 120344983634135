import { uuid } from 'uuidv4'

/**
 * @class
 * @classdesc Model for Ticket on DB
 * * */
export default class Ticket {
  /**
   *Constructor sets a model for setting Data on DB
   * @param {String} id - If empty, generate new UUID
   * @param {Number} price - Ticket price
   * @param {String} sId - SellerID
   * @param {String} sFName - Seller First Name
   * @param {String} sEmail - Seller email
   * @param {String} eventId - Event Id
   * @param {String} globalEventId - GlobalEvent Id
   * @param {String} tType - Ticeket Type
   * @param {Boolean} aSwap - AllowSwap
   * @param {String} sDetails - Swap Details
   * @param {String} refEvent - Reference Event
   * @param {String} ticketSaleId - Ticket IRL ID
   * @param {String} seatDetails - Ticket seat details
   * @param {String} status - Ticket status (active, inactive, deactivated, sold)
   * @param {Array} subTickets - Tickets
   * @param {Boolean} sellAsPatch - Sell tickets as patch
   */

  #TicketObject = {}

  constructor(
    id,
    price,
    sId,
    sFName,
    sEmail,
    eventId,
    globalEventId,
    tType,
    aSwap,
    sDetails,
    refEvent,
    irlId,
    seatDetails,
    status,
    subTickets,
    sellAsPatch
  ) {
    this.#TicketObject.id = id || uuid()
    this.#TicketObject.price = price || 0
    this.#TicketObject.sellerId = sId || ''
    this.#TicketObject.sellerFirstName = sFName || ''
    this.#TicketObject.sellerEmail = sEmail || ''
    this.#TicketObject.eventId = eventId || ''
    this.#TicketObject.globalEventId = globalEventId || ''
    this.#TicketObject.ticketType = tType || 'Tavapilet'
    this.#TicketObject.allowSwap = aSwap || false
    this.#TicketObject.swapDetails = sDetails || ''
    this.#TicketObject.event = refEvent || ''
    this.#TicketObject.salesId = irlId || ''
    this.#TicketObject.seatDetails = seatDetails || ''
    this.#TicketObject.status = status || 'active'
    this.#TicketObject.subTickets = subTickets || []
    this.#TicketObject.sellAsPatch = sellAsPatch || false
  }

  /**
   * Get Ticket Object
   * @return {Object} Ticket JS object
   */
  getTicketObject() {
    return this.#TicketObject
  }
}
