<template>
  <v-flex xs12 class="pa-0">
    <v-stepper
      v-model="stepperStep"
      class="transparent elevation-0 mx-auto d-flex flex-wrap"
    >
      <v-stepper-items
        class="w-100 pa-0 pb-3 pb-md-0"
        :class="{ 'col col-12 col-md-6': stepperStep > 1 }"
      >
        <v-card-actions>
          <h1
            class="white--text font-weight-600"
            :class="{
              headline: $vuetify.breakpoint.xsOnly,
              'font-28': $vuetify.breakpoint.smAndUo
            }"
          >
            Lisa pilet
          </h1>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isAuthenticated && $vuetify.breakpoint.xsOnly"
            text
            class="float-right white--text text-capitalize"
            to="/logi-sisse"
          >
            <v-icon class="pr-2">mdi-account</v-icon>
            Logi sisse
          </v-btn>
          <v-btn
            v-if="isAuthenticated && $vuetify.breakpoint.xsOnly"
            text
            class="float-right white--text text-capitalize"
            :to="'/profiil'"
          >
            <v-icon class="pr-2">mdi-account</v-icon>
            Minu konto
          </v-btn>
        </v-card-actions>
        <v-stepper-content step="1" class="pa-0 mx-auto">
          <first-step
            @didAddNewEventThroughDialog="didAddNewEventThroughDialog"
          />
        </v-stepper-content>

        <v-stepper-content
          step="2"
          :style="$vuetify.breakpoint.mdAndUp ? 'padding-left:130px' : ''"
          class="pa-0 mx-auto"
        >
          <second-step />
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0 mx-auto">
          <v-row class="align-center pa-0 pt-10 pt-md-12 pr-0 pr-md-12">
            <v-col class="col-12 pa-0">
              <v-card class="mb-12 elevation-0" color="transparent">
                <third-step />
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content v-if="selectedEvent" step="4" class="pa-0">
          <fourth-step />
        </v-stepper-content>

        <v-stepper-content
          v-if="selectedEvent"
          step="5"
          class="pa-0 d-flex align-center h-100"
        >
          <v-row
            class="align-center mt-4"
            :class="$vuetify.breakpoint.mdAndDown ? 'flex-column-reverse' : ''"
          >
            <!-- <v-col class="col-12 pa-0">
              <div v-if="!isAuthenticated">
                <login-form
                  v-if="!isAuthenticated && !showRegisterForm"
                  @didClickRegister="showRegisterForm = true"
                />
                <register-form v-if="!isAuthenticated && showRegisterForm" />
              </div>
              <div v-else>
                Kontrolli <br />
                ja kinnita
              </div> -->
            <v-col class="px-0 pt-0 py-12 py-sm-0">
              <div
                v-if="isAuthenticated && stepperStep === 5"
                :class="{
                  'display-1': $vuetify.breakpoint.smAndUp,
                  headline: $vuetify.breakpoint.xsOnly
                }"
                style="color: rgba(0, 237, 123, 0.79);"
              >
                <v-fade-transition hide-on-leave>
                  <div>
                    <span v-if="!ticketPosted">
                      Hei, {{ `${user.firstName} ${user.lastName}` }}
                    </span>
                    <div
                      v-if="!ticketPosted"
                      class="white--text mt-1 mt-sm-4"
                      :class="{
                        headline: $vuetify.breakpoint.smAndUp,
                        'font-18': $vuetify.breakpoint.xsOnly
                      }"
                    >
                      Kontrolli ja lisa
                      {{ userAddedTickets.length === 1 ? 'pilet' : 'piletid' }}
                    </div>
                  </div>
                </v-fade-transition>
                <v-fade-transition>
                  <span v-if="ticketPosted">Pilet lisatud!</span>
                </v-fade-transition>
              </div>
              <!-- <v-col v-if="isAuthenticated" align-center class="mt-6 pl-0">
                <div v-if="!ticketPosted">
                  <v-btn
                    class="d-block mb-4 w-100 text-capitalize rounded-10"
                    style=" height: 50px"
                    color="primary"
                    :loading="loading.state && loading.type == 'createTicket'"
                    @click="addTicket()"
                  >
                    <v-icon
                      left
                      size="20"
                      class="ma-0"
                      style="position:absolute; left:0px"
                      >mdi-check</v-icon
                    >
                    Lisa pilet
                  </v-btn>

                  <v-btn
                    background-color="darkBlue"
                    class="d-block white--text w-100 text-capitalize rounded-10"
                    style="background-color: #015C89; height: 50px"
                    text
                    @click="setStepperStep(stepperStep - 1)"
                  >
                    <v-icon
                      left
                      size="20"
                      class="ma-0"
                      style="position:absolute; left:0px"
                      >mdi-chevron-left</v-icon
                    >
                    {{ $vuetify.lang.t('$vuetify.general.back') }}
                  </v-btn>
                </div>
                <div v-if="ticketPosted">
                  <v-btn
                    class="d-block mb-4 w-100 text-capitalize rounded-10"
                    style=" height: 50px"
                    color="primary"
                    @click="goToLanding()"
                  >
                    Esilehele
                  </v-btn>

                  <v-btn
                    background-color="darkBlue"
                    class="d-block white--text w-100 text-capitalize rounded-10"
                    style="background-color: #015C89; height: 50px"
                    text
                    @click="goToUserTickets()"
                  >
                    Minu piletid
                  </v-btn>
                </div>
              </v-col> -->
              <login-form
                v-if="
                  !isAuthenticated && !showRegisterForm && stepperStep === 5
                "
                @didClickRegister="showRegisterForm = true"
              />
              <register-form
                v-if="!isAuthenticated && showRegisterForm && stepperStep === 5"
              />
            </v-col>
          </v-row>
          <v-row>
            <NextBackButtons
              v-if="stepperStep !== 5"
              :first-button-disabled="false"
              :second-button-disabled="!isAuthenticated"
              @firstButtonOnClick="setStepperStep(stepperStep - 1)"
              @secondButtonOnClick="setStepperStep(stepperStep + 1)"
            >
              <template v-slot:firstButton>
                <v-icon
                  left
                  size="20"
                  class="ma-0"
                  style="position:absolute; left:0px"
                  >mdi-chevron-left</v-icon
                >
                {{ $vuetify.lang.t('$vuetify.general.back') }}
              </template>
              <template v-slot:secondButton>
                {{ $vuetify.lang.t('$vuetify.general.next') }}
                <v-icon
                  left
                  size="20"
                  class="ma-0"
                  style="position:absolute; right:0px"
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </NextBackButtons>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
      <v-col
        v-if="stepperStep > 1"
        cols="12"
        md="6"
        class="pa-0 pt-md-12 pl-md-12"
      >
        <!-- <TicketSummary
          v-if="!$vuetify.breakpoint.xsOnly"
          @addTicket="addTicket()"
        /> -->
        <TicketSummary
          v-if="!$vuetify.breakpoint.xsOnly || stepperStep === 5"
          :ticket-posted="ticketPosted"
          @addTicket="addTicket()"
        />
        <TicketSummaryMobile v-else @addTicket="addTicket()" />
      </v-col>
    </v-stepper>
  </v-flex>
</template>
<script>
import Ticket from '@/firebase/models/Ticket'
import { mapGetters, mapActions } from 'vuex'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'AddTicketStepper',
  components: {
    LoginForm: () =>
      import(
        /* webpackChunkName: "LoginForm" */ '@/components/Login/LoginForm'
      ),
    RegisterForm: () =>
      import(
        /* webpackChunkName: "RegisterForm" */ '@/components/Login/RegisterForm'
      ),
    FirstStep: () =>
      import(
        /* webpackChunkName: "FirstStep" */ '@/components/AddingTicket/FirstStep'
      ),
    SecondStep: () =>
      import(
        /* webpackChunkName: "SecondStep" */ '@/components/AddingTicket/SecondStep'
      ),
    ThirdStep: () =>
      import(
        /* webpackChunkName: "ThirdStep" */ '@/components/AddingTicket/ThirdStep'
      ),
    FourthStep: () =>
      import(/* webpackChunkName: "ThirdStep" */ '@/components/FourthStep'),
    TicketSummary: () =>
      import(
        /* webpackChunkName: "TicketSummary" */ '@/components/AddingTicket/TicketSummary'
      ),
    TicketSummaryMobile: () =>
      import(
        /* webpackChunkName: "TicketSummaryMobile" */ '@/components/AddingTicket/TicketSummaryMobile'
      ),
    NextBackButtons: () =>
      import(
        /* webpackChunkName: "NextBackButtons" */ '@/components/AddingTicket/NextBackButtons'
      )
  },
  mixins: [FormatDate],
  data() {
    return {
      addNewDateVenueDialog: false,
      showRegisterForm: false,
      ticketPosted: false,
      openedPanel: [...Array(this.userAddedTickets).keys()].map((k, i) => i)
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', [
      'events',
      'selectedEvent',
      'stepperStep',
      'addedNewEventFromDialog',
      'addedDateVenueFromDialog',
      'userAddedTickets'
    ]),
    ...mapGetters('authentication', ['isAuthenticated', 'user']),
    ticketSalesId: {
      get() {
        return this.$store.state.events.ticketSalesId
      },
      set(value) {
        this.$store.commit('events/setTicketSalesId', value)
      }
    },
    ticketType: {
      get() {
        return this.$store.state.events.ticketType
      },
      set(value) {
        this.$store.commit('events/setTicketType', value)
      }
    },
    swapDetails: {
      get() {
        return this.$store.state.events.swapDetails
      },
      set(value) {
        this.$store.commit('events/setSwapDetails', value)
      }
    },
    ticketSeat: {
      get() {
        return this.$store.state.events.ticketSeat
      },
      set(value) {
        this.$store.commit('events/setTicketSeat', value)
      }
    },
    selectedPrice: {
      get() {
        return this.$store.state.events.selectedPrice
      },
      set(value) {
        this.$store.commit('events/setSelectedPrice', value)
      }
    },
    allowSwap: {
      get() {
        return this.$store.state.events.allowSwap
      },
      set(value) {
        this.$store.commit('events/setAllowSwap', value)
      }
    },
    sellAsPatch: {
      get() {
        return this.$store.state.events.sellAsPatch
      },
      set(value) {
        this.$store.commit('events/setSellAsPatch', value)
      }
    }
  },
  mounted() {
    this.getAllEvents()
  },
  async created() {
    this.$on('didClickRegister', () => {
      this.showRegisterForm = true
    })
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions('events', [
      'createTicket',
      'createEvent',
      'createPost',
      'getAllEvents'
    ]),
    didAddNewEventThroughDialog() {
      this.$store.commit('events/setAddedNewEventFromDialog', true)
      this.setStepperStep(3)
    },
    setSelectedEvent(newEvent) {
      this.selectedEvent = newEvent
    },
    async addTicket() {
      const newTicket = new Ticket(
        null,
        this.selectedPrice,
        this.user.id,
        this.user.firstName,
        this.user.email,
        this.selectedEvent.id,
        this.selectedEvent.globalEventId,
        this.ticketType,
        this.allowSwap,
        this.swapDetails,
        this.selectedEvent,
        this.ticketSalesId,
        this.ticketSeat,
        null,
        this.sellAsPatch === true ? this.userAddedTickets : [],
        this.sellAsPatch
      ).getTicketObject()

      const eventIndex = this.events
        .map(e => {
          return e.id
        })
        .indexOf(this.selectedEvent.id)

      if (eventIndex === -1) {
        this.selectedEvent.lowestPrice =
          this.userAddedTickets.length <= 1
            ? newTicket.price
            : Math.min(...this.userAddedTickets.map(el => el.price))
        this.selectedEvent.creatorId = this.user.id
        await this.createEvent({
          data: this.selectedEvent
        })
      }
      if (this.userAddedTickets.length <= 1) {
        await this.createTicket({
          data: newTicket,
          requestType: 'create'
        })
      } else if (this.userAddedTickets.length >= 2) {
        if (this.sellAsPatch === true) {
          await this.createTicket({
            data: newTicket,
            requestType: 'create'
          })
        } else {
          this.userAddedTickets.forEach(async ticket => {
            const newTicket2 = new Ticket(
              null,
              ticket.price,
              this.user.id,
              this.user.firstName,
              this.user.email,
              this.selectedEvent.id,
              this.selectedEvent.globalEventId,
              ticket.ticketType,
              this.allowSwap,
              this.swapDetails,
              this.selectedEvent,
              ticket.salesId,
              ticket.seatDetails,
              null,
              this.sellAsPatch === true ? this.userAddedTickets : [],
              ticket.sellAsPatch
            ).getTicketObject()
            await this.createTicket({
              data: newTicket2,
              requestType: 'create'
            })
          })
        }
      }
      this.ticketPosted = true
    },
    goToUserTickets() {
      this.$store.commit('app/setSelectedProfileMenuItem', 'Sinu piletid')
      this.$router.push('/profiil', () => {})
    },
    goToLanding() {
      this.$router.replace('/', () => {})
    },
    setStepperStep(step) {
      if (step > 0 && step < 6)
        this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>
<style lang="scss" scoped>
// .event-select label.v-label.theme--light {
//   color: #005c88;
// }
::v-deep .v-text-field__slot label.v-label.theme--light {
  font-size: 12px;
  opacity: 0.75;
}
.price:after {
  content: '€';
  font-size: 20px;
  position: absolute;
  margin-top: -8px;
}

::v-deep .v-menu__content.v-autocomplete__content {
  max-width: 600px !important;
  min-width: unset !important;
}

::v-deep .text-fields-theme .v-input__slot {
  background: #ffffff !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  border: 0 !important;
}
</style>
