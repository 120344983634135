var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"pa-0",attrs:{"xs12":""}},[_c('v-stepper',{staticClass:"transparent elevation-0 mx-auto d-flex flex-wrap",model:{value:(_vm.stepperStep),callback:function ($$v) {_vm.stepperStep=$$v},expression:"stepperStep"}},[_c('v-stepper-items',{staticClass:"w-100 pa-0 pb-3 pb-md-0",class:{ 'col col-12 col-md-6': _vm.stepperStep > 1 }},[_c('v-card-actions',[_c('h1',{staticClass:"white--text font-weight-600",class:{
            headline: _vm.$vuetify.breakpoint.xsOnly,
            'font-28': _vm.$vuetify.breakpoint.smAndUo
          }},[_vm._v(" Lisa pilet ")]),_c('v-spacer'),(!_vm.isAuthenticated && _vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"float-right white--text text-capitalize",attrs:{"text":"","to":"/logi-sisse"}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" Logi sisse ")],1):_vm._e(),(_vm.isAuthenticated && _vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"float-right white--text text-capitalize",attrs:{"text":"","to":'/profiil'}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" Minu konto ")],1):_vm._e()],1),_c('v-stepper-content',{staticClass:"pa-0 mx-auto",attrs:{"step":"1"}},[_c('first-step',{on:{"didAddNewEventThroughDialog":_vm.didAddNewEventThroughDialog}})],1),_c('v-stepper-content',{staticClass:"pa-0 mx-auto",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'padding-left:130px' : ''),attrs:{"step":"2"}},[_c('second-step')],1),_c('v-stepper-content',{staticClass:"pa-0 mx-auto",attrs:{"step":"3"}},[_c('v-row',{staticClass:"align-center pa-0 pt-10 pt-md-12 pr-0 pr-md-12"},[_c('v-col',{staticClass:"col-12 pa-0"},[_c('v-card',{staticClass:"mb-12 elevation-0",attrs:{"color":"transparent"}},[_c('third-step')],1)],1)],1)],1),(_vm.selectedEvent)?_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"4"}},[_c('fourth-step')],1):_vm._e(),(_vm.selectedEvent)?_c('v-stepper-content',{staticClass:"pa-0 d-flex align-center h-100",attrs:{"step":"5"}},[_c('v-row',{staticClass:"align-center mt-4",class:_vm.$vuetify.breakpoint.mdAndDown ? 'flex-column-reverse' : ''},[_c('v-col',{staticClass:"px-0 pt-0 py-12 py-sm-0"},[(_vm.isAuthenticated && _vm.stepperStep === 5)?_c('div',{class:{
                'display-1': _vm.$vuetify.breakpoint.smAndUp,
                headline: _vm.$vuetify.breakpoint.xsOnly
              },staticStyle:{"color":"rgba(0, 237, 123, 0.79)"}},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('div',[(!_vm.ticketPosted)?_c('span',[_vm._v(" Hei, "+_vm._s(((_vm.user.firstName) + " " + (_vm.user.lastName)))+" ")]):_vm._e(),(!_vm.ticketPosted)?_c('div',{staticClass:"white--text mt-1 mt-sm-4",class:{
                      headline: _vm.$vuetify.breakpoint.smAndUp,
                      'font-18': _vm.$vuetify.breakpoint.xsOnly
                    }},[_vm._v(" Kontrolli ja lisa "+_vm._s(_vm.userAddedTickets.length === 1 ? 'pilet' : 'piletid')+" ")]):_vm._e()])]),_c('v-fade-transition',[(_vm.ticketPosted)?_c('span',[_vm._v("Pilet lisatud!")]):_vm._e()])],1):_vm._e(),(
                !_vm.isAuthenticated && !_vm.showRegisterForm && _vm.stepperStep === 5
              )?_c('login-form',{on:{"didClickRegister":function($event){_vm.showRegisterForm = true}}}):_vm._e(),(!_vm.isAuthenticated && _vm.showRegisterForm && _vm.stepperStep === 5)?_c('register-form'):_vm._e()],1)],1),_c('v-row',[(_vm.stepperStep !== 5)?_c('NextBackButtons',{attrs:{"first-button-disabled":false,"second-button-disabled":!_vm.isAuthenticated},on:{"firstButtonOnClick":function($event){return _vm.setStepperStep(_vm.stepperStep - 1)},"secondButtonOnClick":function($event){return _vm.setStepperStep(_vm.stepperStep + 1)}},scopedSlots:_vm._u([{key:"firstButton",fn:function(){return [_c('v-icon',{staticClass:"ma-0",staticStyle:{"position":"absolute","left":"0px"},attrs:{"left":"","size":"20"}},[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.back'))+" ")]},proxy:true},{key:"secondButton",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.next'))+" "),_c('v-icon',{staticClass:"ma-0",staticStyle:{"position":"absolute","right":"0px"},attrs:{"left":"","size":"20"}},[_vm._v(" mdi-chevron-right ")])]},proxy:true}],null,false,864765039)}):_vm._e()],1)],1):_vm._e()],1),(_vm.stepperStep > 1)?_c('v-col',{staticClass:"pa-0 pt-md-12 pl-md-12",attrs:{"cols":"12","md":"6"}},[(!_vm.$vuetify.breakpoint.xsOnly || _vm.stepperStep === 5)?_c('TicketSummary',{attrs:{"ticket-posted":_vm.ticketPosted},on:{"addTicket":function($event){return _vm.addTicket()}}}):_c('TicketSummaryMobile',{on:{"addTicket":function($event){return _vm.addTicket()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }